import React, { Component, useState } from 'react';
import { Trans, withI18n } from '@lingui/react';
import addToMailchimp from 'gatsby-plugin-mailchimp'

function NewsLetterForm(props) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isDisabled, setDisableState] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(email) // listFields are optional if you are only capturing the email address.
    .then(data => {
      let msg = '';
      if (data.result === 'success')  {
        msg = 'Please check your inbox, we just sent you our latest newsletter.';
      } else {
        if (data.msg.indexOf('already subscribed') > -1) {
          msg = `${email} is already subscribed to our NewsLetter.`
        } else {
          msg = data.msg;
        }
      }
      setMessage(msg);
      setDisableState(true);

      setTimeout(() => {
        reset();
      }, 3000);
    })
    .catch(() => {
      setMessage('Something is wrong. Please try again later.')
    })
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setEmail(value);
  }

  const reset = () => {
    setEmail('');
    setMessage('');
    setDisableState(false);
  }

  return (
    <div className="form-wrapper">
      <form 
        className="form form--inquiry"
        method="post" 
        style={{opacity: isDisabled ? .5 : 1}}
        onSubmit={handleSubmit}
      >
        <fieldset>
          <label htmlFor="email">
            <input name="email" placeholder="Enter email" required id="email" type="email" disabled={isDisabled} value={email} onChange={handleInputChange} />
          </label>
        </fieldset>
        <button className="button button--black" disabled={isDisabled} type="submit">
          <Trans>Subscribe</Trans>
        </button>
      </form>
      { message && <div className="message">{ message }</div> }
    </div>
  )
}

export default withI18n()(NewsLetterForm);
