import React, { Component, Children, useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';
import { Link, navigate } from 'gatsby';
import { useCookies } from "react-cookie";

import  NewsLetterForm  from './NewsLetterForm';

import Artwork from './img/Artwork';
import Instagram from './img/Instagram';
import Twitter from './img/Twitter';
import Facebook from './img/Facebook';
import artworkLogo from './img/artwork-logo.png';
import JustLauched from './img/kickstarter-banner/JustLaunched';

function ComingSoon(props) {
  const [moveAstronauts, setAstronautsMovementState] = useState(false);
  const [moveObelisk, setObeliskMovementState] = useState(false);
  const [showKickStarterLaunched, setShowKickStarterLaunched] = useState(false);
  const [cookies] = useCookies(['ii_kickstarter']);

  useEffect(() => {
    if (cookies['ii_kickstarter']) {
      setShowKickStarterLaunched(false);
    }
  }, [cookies['ii_kickstarter']]);

  const startAnimation = (e) => {
    if (e && e.animationName === 'obelisk') {
      setObeliskMovementState(true);
      setTimeout(() => {
        setAstronautsMovementState(true);
      }, 1000);
    }
    if (e && e.animationName === 'astronautFadeIn') {
      setAstronautsMovementState(true);
    }
  }

  return (
    <div className="coming-soon container">
      <h1 className={`title ${showKickStarterLaunched? 'with-kickstarter-launched' : ''}`}>
        <span>CURRENTLY IN DEVELOPMENT</span>
        {showKickStarterLaunched && <a className="kickstarter-launched-link" target="_blank" href="https://www.kickstarter.com/projects/interstellar-games/lifecycle-champions-arena"><JustLauched /></a>}
        <div className="line"></div>
      </h1>
      <div className={`artwork ${moveObelisk ? 'move-obelisk' : ''} ${moveAstronauts ? 'move-astronauts' : ''}`} >
        <img className="logo" src={artworkLogo} alt='logo' />
        <Artwork onAnimationEnd={startAnimation}/>
      </div>
      <div className="middle-content">
        <div className="left">
          <h3>SUBSCRIBE TO OUR NEWSLETTER FOR UPDATES ON OUR GAMES</h3>
          <NewsLetterForm />
        </div>
        <div className="right">
          <h3>FOLLOW US AND STAY CONNECTED</h3>
          <div className="social-links-wrapper">
            <div className="social-links">
              <a className="social-links__item _twitter" href="https://twitter.com/InterstellarMTL" target="_blank"><Twitter /></a>
              <a className="social-links__item _instagram" href="https://www.instagram.com/interstellarinteractive/" target="_blank"><Instagram /></a>
              <a className="social-links__item _facebook" href="https://www.facebook.com/InterstellarInteractive" target="_blank"><Facebook /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withI18n()(ComingSoon);

