import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link, navigate } from 'gatsby';
import { Trans, withI18n } from '@lingui/react';
import _ from 'lodash';
import { prefix } from '../i18n-config';

import ComingSoon from '../components/ComingSoon';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    const { pageContext } = this.props;
    this.langPrefix = prefix(pageContext.lang);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const { location } = this.props;
    const lang = this.langPrefix;

    return (
      <div className="page front-page">
        <Helmet defaultTitle="Interstellar Interactive" titleTemplate="%s - Announcement soon">
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Interstellar Interactive" />
          <title>Interstellar Interactive</title>
          <link
            rel="canonical"
            href={`https://interstellarinteractive.com${location.pathname}`}
          />
          <html lang={lang} />
          <meta name="description" content="We are Interstellar Interactive, a video game studio from Montreal, Canada. Our ambitions go beyond fundamentals and the space frontier is our trajectory." />
        </Helmet>
        <div className="container">
          <ComingSoon />
        </div>
      </div>
    );
  }
}

export default withI18n()(Home);
